import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { style: {"min-height":"85vh"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_notifications = _resolveComponent("notifications")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["content", {'with-footer':_ctx.$store.state.showFooter}])
    }, [
      _createVNode(_component_NavBar),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view)
      ]),
      _createVNode(_component_notifications, {
        group: "general",
        position: "bottom right"
      })
    ], 2),
    (_ctx.$store.state.showFooter)
      ? (_openBlock(), _createBlock(_component_Footer, {
          key: 0,
          class: "footer"
        }))
      : _createCommentVNode("", true)
  ]))
}